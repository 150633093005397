import React from 'react'
import {
    UncontrolledCarousel
  } from 'reactstrap'
import img1 from '../img/img1.jpeg'
import img2 from '../img/img2.jpeg'
import img3 from '../img/img3.jpeg'
import img4 from '../img/img4.jpeg'
import img5 from '../img/img5.jpeg'
import img6 from '../img/img6.jpeg'
import img7 from '../img/img7.jpeg'
import img8 from '../img/img8.jpeg'
import img9 from '../img/img9.jpeg'
import img10 from '../img/img10.jpeg'
import img11 from '../img/img11.jpeg'
import img12 from '../img/img12.jpeg'
import img13 from '../img/img13.jpeg'
import img14 from '../img/img14.jpeg'
import img15 from '../img/img15.jpeg'
import img16 from '../img/img16.jpeg'
import img17 from '../img/img17.jpeg'
import img18 from '../img/img18.jpeg'
import img19 from '../img/img19.jpeg'


const items = [
    {
        src:img1,
        altText:'',
        caption:''
    },
    {
        src:img2,
        altText:'',
        caption:'',
        header:''
    },
    {
        src:img3,
        altText:'',
        caption:''
    },
    {
        src:img4,
        altText:'',
        caption:''
    },
    {
        src:img5,
        altText:'',
        caption:''
    },
    {
        src:img6,
        altText:'',
        caption:''
    },
    {
        src:img7,
        altText:'',
        caption:''
    },
    {
        src:img8,
        altText:'',
        caption:''
    },
    {
        src:img9,
        altText:'',
        caption:''
    },
    {
        src:img10,
        altText:'',
        caption:''
    },
    {
        src:img11,
        altText:'',
        caption:''
    },
    {
        src:img12,
        altText:'',
        caption:''
    },
    {
        src:img13,
        altText:'',
        caption:''
    },
    {
        src:img14,
        altText:'',
        caption:''
    },
    {
        src:img15,
        altText:'',
        caption:''
    },
    {
        src:img16,
        altText:'',
        caption:''
    },
    {
        src:img17,
        altText:'',
        caption:''
    },
    {
        src:img18,
        altText:'',
        caption:''
    },
    {
        src:img19,
        altText:'',
        caption:''
    },


]

const Carousel = ()=> <UncontrolledCarousel indicators={false} items={items}/>

export default Carousel