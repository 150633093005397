import React, { Component } from 'react'
import { } from 'reactstrap'
import Menu from './Menu'
import About from './About'


class App extends Component {
  render() {
    return (
      <div >
        <Menu/>
        <About/>
      </div>
    );
  }
}

export default App;
