import React from 'react'
import '../css/About.css'
import Logo from '../img/Logotipotexto.svg'




export default function About () {
    return(
        <div className='bg-light about w-100'>
           <div className='about-logo'>
           <img src={Logo} className='logo' alt='logo'/>
           </div>
           <div className='col'>
               <div className='col-12 col-md-6'>
                    <strong>Contact Us</strong>
                    <div className='foot-line'>
                        163 Brookside Farm road, Newburgh NY 12550
                        <br/><strong>Email:</strong> LanzaTransportation@Yahoo.com
                        <br/><strong>Phone: 845-401-5151</strong>
                    </div>
                        
               </div>
               <div className='col-12 col-md-6'>
                   
               </div>
           </div>
           <div className='foot-line'>
         
           </div>
        </div>
    )

}