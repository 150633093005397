import React from 'react'
import '../css/Contact.css'
import carImg from '../img/brokenCar.jpg'




export default function Contact () {
    return(
        <div className='container'>
            <center><strong className='contact-header'>Need Emergency Help? <br className='br-menu'/>Give Us a Call!</strong></center>
            <div className='m-3'>
                <div className='my-2'>
                    <p className='w-75 mx-auto'>
                    We’re here for you 24 hours a day, seven days a week. Give us a call when you’re in need of a quick tow or any of our services.  Our crew is dedicated to making unpredictable vehicle issues as convenient as possible for you.
                    </p>
                </div>
                <div className='row'>
                    <div className='col-12 col-md-6 contact-text'>
                    <div className='my-2'>
                    <br/> <strong>Phone Number:</strong>
                    <br/> 845-401-5151
                </div>
                <div className='my-2'>
                <strong>Tambien Hablamos Español:</strong>
                <br/> 845-234-6181
                </div>
                <div className='my-2'>
                <strong>Hours of Operation</strong>
                <br/> 24/7 Roadside Service
                </div>
                <div className='my-2'>
                <strong>Serving</strong>
                <br/> Newburgh, NY & Surrounding Areas
                </div>
                    </div>

                    <div className='col-12 col-md-6'>
                        <img src={carImg} alt='Broken Car' className='contact-img'/>
                    </div>
                </div>
            </div>
            
        </div>
    )

}