import React from 'react'
import Carousel from './Carousel'
import {Jumbotron, Button} from 'reactstrap'
import {Link} from 'react-router-dom'



export default function Home () {
    return(
        <div className='container'>
        <Jumbotron className='text-jumbo'>
            <h1>
                Need a Tow?
            </h1>
            <h4>Call Lanza Transportation & Towing </h4>
            <h4>Available 24/7</h4>
            <Button color='dark' tag={Link} to='/Contact'>Contact</Button>
        </Jumbotron>
        <Carousel />

        </div>
    )

}