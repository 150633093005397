import React, { Component } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap'
import {
    Link,
    BrowserRouter,
    Route
} from 'react-router-dom'
import logo from '../img/Logotiponegro.svg'
import '../css/Menu.css'
import Home from './Home'
import Contact from './Contact'
import Services from './Services'

export default class Menu extends Component {
    constructor(props) {
        super(props)
        this.toggle = this.toggle.bind(this)
        this.state = {
            isOpen: false
        }

    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    render() {
        return (
            <BrowserRouter>
                <div>
                    <Navbar color='light' light expand='md'>
                        <NavbarBrand tag={Link} to='/' ><img src={logo} className='Logo' alt='logo'/></NavbarBrand>
                        <div className='menu-text text-warning'>
                            Lanza <br className='br-menu'/>Transportation & Towing
                        </div>
                        <NavbarToggler onClick={this.toggle} />
                        <Collapse isOpen={this.state.isOpen} navbar>
                            <Nav className='ml-auto' navbar>
                                <NavItem>
                                    <NavLink tag={Link} to='/'>Home</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} to='/Services'>Services</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} to='/Contact'>Contact</NavLink>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </Navbar>
                    <div className='app-content'>
                        <Route exact path='/' component={Home} />
                        <Route path='/contact' component={Contact} />
                        <Route path='/services' component={Services} />
                    </div>
                    
                </div>

            </BrowserRouter>
        )
    }
}
