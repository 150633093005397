import React from 'react'




export default function Services () {
    return(
        <div className='mx-2 row'>
            <div className='col-12 col-md-4'>
                <h3>24/7 Roadside Assistance</h3>
                <p>
                If your vehicle has experienced a breakdown or mechanical disablement, We are available to provide roadside help.
                </p>
            </div>
            <div className='col-12 col-md-4'>
                <h3>Jump Start</h3>
                <p>
                    If your car battery is dead and you need a jump, We have the spark you need.
                </p>
            </div>
            <div className='col-12 col-md-4'>
                <h3>Tire Change</h3>
                <p>
                Whether you’ve got a flat, a tire that’s blown out, a hole or tear in the sidewall, or any other roadside assistance emergency requiring a tire change, our qualified crew can change your tire and get you back on the road quickly.
                </p>
            </div>
            <div className='col-12 col-md-4'>
                <h3>Car Door Unlock</h3>
                <p>
                    If You ever get locked out of your car, Don’t damage your vehicle. You need a trained expert who knows all methods to safely open a car door. We’ll get you back on your way, fast.
                </p>
            </div>
            <div className='col-12 col-md-4'>
                <h3>Gas Delivery</h3>
                <p>
                    Running out of gas doesnt mean you're out of luck, We deliver a couple gallons of fuel to get you and your vehicle to the nearest service station. All for a low flat rate.
                </p>
            </div>
            <div className='col-12 col-md-4'>
                <h3>Ditch & Winch Services</h3>
                <p>
                You're stuck in a ditch, You and your vehicle found yourself off the road due to snow, ice, rain. Doesnt matter, call us and get a fast, reliable winch and towing service.
                </p>
            </div>
            <div className='col-12 col-md-4'>
                <h3>Short & Long Distance Towing</h3>
                <p>
                Ask Us how we can accommodate your needs, from getting your car to the nearest mechanic, or as far as you need.
                </p>
            </div>
            <div className='col-12 col-md-4'>
                <h3>Car Shipping Services</h3>
                <p>
                    We offer car shipping services, ask us about our rates.
                </p>
            </div>
            <div className='col-12 col-md-4'>
                <h3>Delivery from Auto Auction</h3>
                <p>
                    We pick up cars from the auto auction in: IAA, COPART, MANHEIM to the warehouse, We also do residential and port deliveries as well.
                </p>
            </div>
            <div className='col-12 col-md-4'>
                <h3>Junk Car Removal</h3>
                <p>
                    We get rid of that old junk car that is bothering you.
                </p>
            </div>
            <div className='col-12 col-md-4'>
                <h3>Material Delivery</h3>
                <p>
                    Need any materials and dont have a pick up, Dont worry give us a call and We’ll deliver them for you.
                </p>
            </div>
        </div>
    )

}